<template>
  <div>
    <div class="row no-gutters text-center">
      <div class="col pb-2" v-if="$route.name === 'VesselAuditDue'">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setVesselsToTable(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setVesselsToTable(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setVesselsToTable(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col" v-else>

      </div>
      <div class="col-3 pb-2" v-if="$route.name === 'VesselAuditDue'">
        <input type="text" name="vesselSearch" class="form-control form-control-sm" v-model="params.query" placeholder="Search Vessel">
      </div>
    </div>
    <div class="row no-gutters text-center p-0">
      <div class="col-sm-12">
        <div class="d-flex" style="height: auto; overflow-y: auto; overflow-x: hidden">
          <div id = "collapsible_table" class="col-6 p-0 m-0" style="overflow-x: hidden; overflow-y: hidden; position: relative">
            <table class="small e-tbl">
              <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
              <tr>
                <th :rowspan="$route.name !== 'VesselAuditDue' ? 2 : 3" class="align-middle" style="min-width: 30px;" scope="col">#</th>
                <th :style="{minWidth : `calc((100vw - ${adjustColumnPixel}) * 0.125) !important`}" :rowspan="$route.name !== 'VesselAuditDue' ? 2 : 3" class="align-middle no-select cursor-pointer" scope="col" @click="sorterArrowSetDirection('name')">
                  NAME
                </th>
                <th :style="{minWidth : `calc((100vw - ${adjustColumnPixel}) * 0.125) !important`}" :rowspan="$route.name !== 'VesselAuditDue' ? 2 : 3" class="align-middle no-select cursor-pointer" scope="col" @click="sorterArrowSetDirection('flag_id')">
                  IMO NO
                </th>
                <th :style="{minWidth : `calc((100vw - ${adjustColumnPixel}) * 0.125) !important`}" :rowspan="$route.name !== 'VesselAuditDue' ? 2 : 3" class="align-middle no-select cursor-pointer" scope="col" @click="sorterArrowSetDirection('flag_id')">
                  FLAG
                </th>
                <th :style="{minWidth : `calc((100vw - ${adjustColumnPixel}) * 0.125) !important`}" :rowspan="$route.name !== 'VesselAuditDue' ? 2 : 3" class="align-middle no-select cursor-pointer p-0" scope="col" @click="sorterArrowSetDirection('management_office_id')">
                  BRANCH OFFICE
                </th>

                <th class="align-middle plan-content" :rowspan="$route.name !== 'VesselAuditDue' ? 1 : 2" scope="col" colspan="6">PLAN</th>
                <th class="align-middle background-0000ff left-solid-border-black right-solid-border-black" colspan="3" scope="col">SMS</th>
                <th class="align-middle background-548235 left-solid-border-black right-solid-border-black" colspan="3" scope="col">ISPS</th>
                <th class="align-middle background-dc3545 left-solid-border-black right-solid-border-black" colspan="3" scope="col">CYBER</th>

              </tr>


              <tr v-if="$route.name === 'VesselAuditDue'">
                <th colspan="3" class="p-0 left-solid-border-black right-solid-border-black" style="min-width: 345px">
                  <div class="row col-12 m-auto p-0">
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'Overdue'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#f35656'"
                        :display-count="vessel_audit_dues_count.sms.overdue.count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ff0000'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'30 Days'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#ff8c00'"
                        :display-count="vessel_audit_dues_count.sms['30days'].count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ffa500'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'90 Days'"
                        :display-text-bg-color="'#fbfb98'"
                        :display-count="vessel_audit_dues_count.sms['90days'].count"
                        :display-count-bg-color="'#f3f309'"
                      />
                    </div>
                  </div>
                </th>
                <th colspan="3" class="p-0 left-solid-border-black right-solid-border-black" style="min-width: 345px">
                  <div class="row col-12 m-auto p-0">
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'Overdue'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#f35656'"
                        :display-count="vessel_audit_dues_count.isps.overdue.count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ff0000'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'30 Days'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#ff8c00'"
                        :display-count="vessel_audit_dues_count.isps['30days'].count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ffa500'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'90 Days'"
                        :display-text-bg-color="'#fbfb98'"
                        :display-count="vessel_audit_dues_count.isps['90days'].count"
                        :display-count-bg-color="'#f3f309'"
                      />
                    </div>
                  </div>
                </th>
                <th colspan="3" class="p-0 left-solid-border-black right-solid-border-black" style="min-width: 345px">
                  <div class="row col-12 m-auto p-0">
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'Overdue'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#f35656'"
                        :display-count="vessel_audit_dues_count.cyber.overdue.count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ff0000'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'30 Days'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#ff8c00'"
                        :display-count="vessel_audit_dues_count.cyber['30days'].count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ffa500'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'90 Days'"
                        :display-text-bg-color="'#fbfb98'"
                        :display-count="vessel_audit_dues_count.cyber['90days'].count"
                        :display-count-bg-color="'#f3f309'"
                      />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th class="plan-content" style="min-width: 200px">PLACE</th>
                <th class="plan-content" style="min-width: 300px">AUDITORS</th>
                <th class="plan-content" style="min-width: 150px">DATE</th>
                <th class="plan-content" style="min-width: 200px">REMARKS</th>
                <th class="plan-content" style="min-width: 150px">LAST UPDATED BY</th>
                <th class="plan-content" style="min-width: 150px">LAST UPDATED AT</th>
                <th class="left-solid-border-black" style="width: 156px; min-width: 156px">DATE OF LAST AUDIT</th>
                <th style="width: 94px; min-width: 94px">NEXT DUE</th>
                <th class="right-solid-border-black" style="width: 95px; min-width: 95px">EXTENSION</th>
                <th class="left-solid-border-black" style="width: 156px; min-width: 156px">DATE OF LAST AUDIT</th>
                <th style="width: 94px; min-width: 94px">NEXT DUE</th>
                <th class="right-solid-border-black" style="width: 95px; min-width: 95px">EXTENSION</th>
                <th class="left-solid-border-black" style="width: 156px; min-width: 156px">DATE OF LAST AUDIT</th>
                <th style="width: 94px; min-width: 94px">NEXT DUE</th>
                <th class="right-solid-border-black" style="width: 95px; min-width: 95px">EXTENSION</th>
              </tr>
              </thead>
              <tbody>

              <tr v-if="loading">
                <td colspan="100%" style="border-right: none !important;">
                  <app-loading style="visibility: hidden"></app-loading>
                </td>
              </tr>
              <template v-else>
                <template v-for="(vessel, index) in vessel_audit_dues">
                  <tr :title="vessel.vessel_name" @mouseover="highlightRow(index, true)" @mouseout="highlightRow(index, false)">
                    <td :class="'row_' + index" class="align-middle">{{ index + pagination.from }}</td>
                    <td :class="'row_' + index" class="align-middle text-uppercase">{{ vessel.vessel_name ?? ''}}</td>
                    <td :class="'row_' + index" class="align-middle text-uppercase">{{ vessel.vessel_imo_no?? '' }}</td>
                    <td :class="'row_' + index" class="align-middle text-uppercase">{{ vessel.flag ?? '' }}</td>
                    <td :class="'row_' + index" class="align-middle text-uppercase right-solid-border-black">{{ vessel.office ? vessel.office : '' }}</td>

                                        <td :class="'row_' + index" class="align-middle plan-content right-solid-border-black plan-data" @dblclick="showUpdateVesselPlanModal(vessel)" style="max-width: 300px; border-left: none !important">
                                          <div>
                                            {{ vessel.plan?.place ? vessel.plan?.place : '' }} &nbsp;
                                          </div>

                                        </td>
                                        <td :class="'row_' + index" class="align-middle plan-content plan-data right-solid-border-black" @dblclick="showUpdateVesselPlanModal(vessel)">{{ vessel.plan?.auditors.map(auditor => auditor.name).join(' & ') }}</td>
                                        <td :class="'row_' + index" class="align-middle plan-content plan-data right-solid-border-black" @dblclick="showUpdateVesselPlanModal(vessel)">{{ vessel.plan?.date?.human ? vessel.plan?.date?.human : null }}</td>
                                        <td :class="'row_' + index" class="align-middle plan-content plan-data right-solid-border-black" @dblclick="showUpdateVesselPlanModal(vessel)" style="max-width: 300px">
                                          {{ vessel.plan?.remarks ? vessel.plan?.remarks : '' }}
                                        </td>
                                        <td :class="'row_' + index" class="align-middle plan-content plan-data right-solid-border-black" style="max-width: 300px">
                                          {{ vessel.plan?.last_updated_by ? vessel.plan?.last_updated_by : '' }}
                                        </td>
                                        <td :class="'row_' + index" class="align-middle right-solid-border-black plan-content plan-data" style="max-width: 300px">
                                          {{ vessel.plan?.last_updated_at ? vessel.plan?.last_updated_at : '' }}
                                        </td>

                                        <td :class="'row_' + index" class="align-middle left-solid-border-black">{{ vessel.sms.last_audit_date === null ? '' : vessel.sms.last_audit_date.human }}</td>
                                        <td class="align-middle" :class="['row_' + index, getClassByCount(vessel.id, 'sms')]">{{ vessel.sms.next_due ?? ''}}</td>
                                        <td :class="'row_' + index" class="align-middle right-solid-border-black" :style="vessel.sms.extension === 1 ? 'color: red;' : ''">{{ vessel.sms.extension === null ? '' : vessel.sms.extension === 1 ? 'YES' : 'NO'}}</td>


                                        <td :class="'row_' + index" class="align-middle left-solid-border-black">{{ vessel.isps.last_audit_date === null ? '' : vessel.isps.last_audit_date.human }}</td>
                                        <td class="align-middle" :class="['row_' + index, getClassByCount(vessel.id, 'isps')]">{{ vessel.isps.next_due ?? ''}}</td>
                                        <td :class="'row_' + index" class="align-middle right-solid-border-black" :style="vessel.isps.extension === 1 ? 'color: red;' : ''">{{ vessel.isps.extension === null ? '' : vessel.isps.extension === 1 ? 'YES' : 'NO'}}</td>


                                        <td :class="'row_' + index" class="align-middle left-solid-border-black">{{ vessel.cyber.last_audit_date === null ? '' : vessel.cyber.last_audit_date.human }}</td>
                                        <td class="align-middle" :class="['row_' + index, getClassByCount(vessel.id, 'cyber')]">{{ vessel.cyber.next_due ?? ''}}</td>
                                        <td :class="'row_' + index" class="align-middle right-solid-border-black" :style="vessel.cyber.extension === 1 ? 'color: red;' : ''">{{ vessel.cyber.extension === null ? '' : vessel.cyber.extension === 1 ? 'YES' : 'NO'}}</td>
                  </tr>
                </template>
              </template>

              </tbody>
            </table>
          </div>
          <div id = "data_table" class="col-6 p-0 m-0" style="overflow-y: hidden;" :style="{overflowX : loading === true ? 'hidden' : 'auto'}">
            <table class="small e-tbl" >
              <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
              <tr>
                <th class="align-middle plan-content" :rowspan="$route.name !== 'VesselAuditDue' ? 1 : 2" scope="col" colspan="6">PLAN</th>
                <th class="align-middle background-0000ff left-solid-border-black right-solid-border-black" colspan="3" scope="col">SMS</th>
                <th class="align-middle background-548235 left-solid-border-black right-solid-border-black" colspan="3" scope="col">ISPS</th>
                <th class="align-middle background-dc3545 left-solid-border-black right-solid-border-black" colspan="3" scope="col">CYBER</th>
              </tr>
              <tr v-if="$route.name === 'VesselAuditDue'">
                <th colspan="3" class="p-0 left-solid-border-black right-solid-border-black" style="min-width: 345px">
                  <div class="row col-12 m-auto p-0">
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'Overdue'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#f35656'"
                        :display-count="vessel_audit_dues_count.sms.overdue.count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ff0000'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'30 Days'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#ff8c00'"
                        :display-count="vessel_audit_dues_count.sms['30days'].count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ffa500'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'90 Days'"
                        :display-text-bg-color="'#fbfb98'"
                        :display-count="vessel_audit_dues_count.sms['90days'].count"
                        :display-count-bg-color="'#f3f309'"
                      />
                    </div>
                  </div>
                </th>
                <th colspan="3" class="p-0 left-solid-border-black right-solid-border-black" style="min-width: 345px">
                  <div class="row col-12 m-auto p-0">
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'Overdue'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#f35656'"
                        :display-count="vessel_audit_dues_count.isps.overdue.count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ff0000'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'30 Days'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#ff8c00'"
                        :display-count="vessel_audit_dues_count.isps['30days'].count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ffa500'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'90 Days'"
                        :display-text-bg-color="'#fbfb98'"
                        :display-count="vessel_audit_dues_count.isps['90days'].count"
                        :display-count-bg-color="'#f3f309'"
                      />
                    </div>
                  </div>
                </th>
                <th colspan="3" class="p-0 left-solid-border-black right-solid-border-black" style="min-width: 345px">
                  <div class="row col-12 m-auto p-0">
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'Overdue'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#f35656'"
                        :display-count="vessel_audit_dues_count.cyber.overdue.count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ff0000'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'30 Days'"
                        :display-text-color="'#fffeff'"
                        :display-text-bg-color="'#ff8c00'"
                        :display-count="vessel_audit_dues_count.cyber['30days'].count"
                        :display-count-color="'#fffeff'"
                        :display-count-bg-color="'#ffa500'"
                      />
                    </div>
                    <div class="col-4 m-0 p-0">
                      <data-counter
                        :display-text="'90 Days'"
                        :display-text-bg-color="'#fbfb98'"
                        :display-count="vessel_audit_dues_count.cyber['90days'].count"
                        :display-count-bg-color="'#f3f309'"
                      />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th class="plan-content" style="min-width: 200px">PLACE</th>
                <th class="plan-content" style="min-width: 300px">AUDITORS</th>
                <th class="plan-content" style="min-width: 150px">DATE</th>
                <th class="plan-content" style="min-width: 200px">REMARKS</th>
                <th class="plan-content" style="min-width: 150px">LAST UPDATED BY</th>
                <th class="plan-content" style="min-width: 150px">LAST UPDATED AT</th>
                <th class="left-solid-border-black" style="width: 156px; min-width: 156px">DATE OF LAST AUDIT</th>
                <th style="width: 94px; min-width: 94px">NEXT DUE</th>
                <th class="right-solid-border-black" style="width: 95px; min-width: 95px">EXTENSION</th>
                <th class="left-solid-border-black" style="width: 156px; min-width: 156px">DATE OF LAST AUDIT</th>
                <th style="width: 94px; min-width: 94px">NEXT DUE</th>
                <th class="right-solid-border-black" style="width: 95px; min-width: 95px">EXTENSION</th>
                <th class="left-solid-border-black" style="width: 156px; min-width: 156px">DATE OF LAST AUDIT</th>
                <th style="width: 94px; min-width: 94px">NEXT DUE</th>
                <th class="right-solid-border-black" style="width: 95px; min-width: 95px">EXTENSION</th>
              </tr>
              </thead>
              <tbody>

              <tr v-if="loading">
                <td colspan="100%" style="border-left: none !important;">
                  <app-loading style="margin-right: 1500px"></app-loading>
                </td>
              </tr>

              <template v-else>
                <template v-for="(vessel, index) in vessel_audit_dues">
                  <tr :title="vessel.vessel_name" @mouseover="highlightRow(index, true)" @mouseout="highlightRow(index, false)">
                    <td :class="'row_' + index" class="align-middle plan-content right-solid-border-black plan-data" @dblclick="showUpdateVesselPlanModal(vessel)" style="max-width: 300px; border-left: none !important">
                      <div>
                        {{ vessel.plan?.place ? vessel.plan?.place : '' }} &nbsp;
                      </div>

                    </td>
                    <td :class="'row_' + index" class="align-middle plan-content plan-data right-solid-border-black" @dblclick="showUpdateVesselPlanModal(vessel)">{{ vessel.plan?.auditors.map(auditor => auditor.name).join(' & ') }}</td>
                    <td :class="'row_' + index" class="align-middle plan-content plan-data right-solid-border-black" @dblclick="showUpdateVesselPlanModal(vessel)">{{ vessel.plan?.date?.human ? vessel.plan?.date?.human : null }}</td>
                    <td :class="'row_' + index" class="align-middle plan-content plan-data right-solid-border-black" @dblclick="showUpdateVesselPlanModal(vessel)" style="max-width: 300px">
                      {{ vessel.plan?.remarks ? vessel.plan?.remarks : '' }}
                    </td>
                    <td :class="'row_' + index" class="align-middle plan-content plan-data right-solid-border-black" style="max-width: 300px">
                      {{ vessel.plan?.last_updated_by ? vessel.plan?.last_updated_by : '' }}
                    </td>
                    <td :class="'row_' + index" class="align-middle right-solid-border-black plan-content plan-data" style="max-width: 300px">
                      {{ vessel.plan?.last_updated_at ? vessel.plan?.last_updated_at : '' }}
                    </td>

                    <td :class="'row_' + index" class="align-middle left-solid-border-black">{{ vessel.sms.last_audit_date === null ? '' : vessel.sms.last_audit_date['date-picker'] | moment('D MMM YYYY') }}</td>
                    <td class="align-middle" :class="['row_' + index, getClassByCount(vessel.id, 'sms')]">{{ vessel.sms.next_due | moment('D MMM YYYY') }}</td>
                    <td :class="'row_' + index" class="align-middle right-solid-border-black" :style="vessel.sms.extension === 1 ? 'color: red;' : ''">{{ vessel.sms.extension === null ? '' : vessel.sms.extension === 1 ? 'YES' : 'NO'}}</td>


                    <td :class="'row_' + index" class="align-middle left-solid-border-black">{{ vessel.isps.last_audit_date === null ? '' : vessel.isps.last_audit_date['date-picker'] | moment('D MMM YYYY') }}</td>
                    <td class="align-middle" :class="['row_' + index, getClassByCount(vessel.id, 'isps')]">{{ vessel.isps.next_due | moment('D MMM YYYY') }}</td>
                    <td :class="'row_' + index" class="align-middle right-solid-border-black" :style="vessel.isps.extension === 1 ? 'color: red;' : ''">{{ vessel.isps.extension === null ? '' : vessel.isps.extension === 1 ? 'YES' : 'NO'}}</td>


                    <td :class="'row_' + index" class="align-middle left-solid-border-black">{{ vessel.cyber.last_audit_date === null ? '' : vessel.cyber.last_audit_date['date-picker'] | moment('D MMM YYYY') }}</td>
                    <td class="align-middle" :class="['row_' + index, getClassByCount(vessel.id, 'cyber')]">{{ vessel.cyber.next_due | moment('D MMM YYYY') }}</td>
                    <td :class="'row_' + index" class="align-middle right-solid-border-black" :style="vessel.cyber.extension === 1 ? 'color: red;' : ''">{{ vessel.cyber.extension === null ? '' : vessel.cyber.extension === 1 ? 'YES' : 'NO'}}</td>
                  </tr>
                </template>
              </template>
              </tbody>
            </table>
          </div>
        </div>




      </div>
    </div>


    <div class="modal fade" data-backdrop="static" data-keyboard="false" id="updateVesselPlanModal" tabindex="-1">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <form class="modal-content" @submit.prevent="updateVesselPlan">
          <div class="modal-header">
            <div class="modal-title w-100 text-center">
              <h5 class="font-weight-bold">VESSEL ({{ selectedVesselForPlan.vessel_name }}) PLAN</h5>
            </div>
          </div>
          <div class="modal-body">
            <!--          FOR PLAN PLACE -->
            <div class="form-group row no-gutters mb-1">
              <label for="last_name" class="font-weight-bold col-sm-3 col-form-label text-left">PLACE</label>
              <div class="col-sm-9">
                <input type="text" id="plan_place" class="form-control form-control-sm" v-model="selectedVesselForPlan.place">
              </div>
            </div>
            <!--          FOR PLAN AUDITORS -->
            <div class="form-group row no-gutters mb-1">
              <label for="plan_auditors" class="font-weight-bold col-sm-3 col-form-label text-left">AUDITORS</label>
              <div class="col-sm-9">
                <multi-select-with-search
                  :key="selectedVesselForPlan.vessel_id + '_' + resetButtonClick"
                  id="plan_auditors"
                  :items="leadAndNonLeadAuditors" :entity="'Auditors'"
                  @doneSelected="setPlanAuditors"
                  :selected="selectedVesselForPlan.auditors"
                >
                </multi-select-with-search>
              </div>



            </div>
            <!--          FOR PLAN DATE -->
            <div class="form-group row no-gutters mb-1">
              <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label text-left">DATE</label>
              <div class="col-sm-9">
                <input type="date" :min="minimumDate"  class="form-control form-control-sm" v-model="selectedVesselForPlan.date">
              </div>
            </div>
            <!--          FOR PLAN REMARKS -->
            <div class="form-group row no-gutters mb-1">
              <label for="last_name" class="font-weight-bold col-sm-3 col-form-label text-left">REMARKS</label>
              <div class="col-sm-9">
                <input type="text" id="plan_remarks" class="form-control form-control-sm" v-model="selectedVesselForPlan.remarks">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-success" @click.prevent="setDefaultData">Reset</button>
            <button type="button" class="btn btn-sm btn-secondary" @click.prevent="resetSelectedVesselPlan">Close</button>
            <button type="submit" class="btn btn-sm btn-primary" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" :disabled="hasChanges === false">Update Vessel Plan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading";

import _ from 'lodash';
import {AlertService} from "@/services/AlertService";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import SorterArrowMixin from "@/mixins/SorterArrowMixin";
import DataCounter from "@/components/common/DataCounter.vue";
import SorterArrow from "@/components/common/SorterArrow.vue";
import DateMixin from "@/mixins/DateMixin";
import MultiSelectWithSearch from "@/components/MultiSelectWithSearch.vue";
import AuditorMixin from "@/mixins/AuditorMixin";
import requestLoaderMixin from "@/mixins/requestLoaderMixin";
import {DataService} from "@/services/DataService";

export default {

  mixins: [vueAppMixin,PaginationMixin, SorterArrowMixin, DateMixin, AuditorMixin, requestLoaderMixin],
  components: {MultiSelectWithSearch, SorterArrow, DataCounter, AppLoading},

  name: 'VesselAuditDue',
  data() {
    return {
      params: {
        active: 1,
        page:1,
        query: '',
        order_by : 'name',
        direction : 'asc'
      },
      loading: false,
      selectedVesselForPlan : {
        vessel_id : null,
        vessel_name : '',
        place : '',
        auditors : [],
        date : null,
        remarks : ''
      },
      selectedVesselCurrentData : {
        place : '',
        auditors : [],
        date : null,
        remarks : ''
      },
      resetButtonClick : false,
      adjustColumnPixel : '354px'
    }
  },
  methods: {
    ...mapActions([
      'getAuditDues',
      'getAuditDueCounts',
      'updateAuditDuePlanPlanByVesselId',
    ]),
    highlightRow(vessel_index, add = true) {
      if (add === true) {
        $('.row_' + vessel_index).addClass('list-hover')
      } else {
        $('.row_' + vessel_index).removeClass('list-hover')
      }
    },
    setPlanAuditors(auditors) {
      this.selectedVesselForPlan.auditors = auditors.selected.length > 0 ? auditors.selected : [];
    },
    resetSelectedVesselPlan() {
      this.selectedVesselForPlan = {
        vessel_id : null,
        vessel_name : '',
        place : '',
        auditors : [],
        date : null,
        remarks : ''
      };
      this.selectedVesselCurrentData = {
        place : '',
        auditors : [],
        date : null,
        remarks : ''
      };
      this.resetButtonClick = false;
      $('#updateVesselPlanModal').modal('hide');
    },
    async setDefaultData() {
      this.selectedVesselForPlan.place = '';
      this.selectedVesselForPlan.auditors = [];
      this.selectedVesselForPlan.date = null;
      this.selectedVesselForPlan.remarks = '';
      this.resetButtonClick = true;
    },
    async updateVesselPlan() {
      await this.showLoaderRequest('UPDATING VESSEL PLAN, PLEASE WAIT...');
      const updateResponse = await this.updateAuditDuePlanPlanByVesselId(this.selectedVesselForPlan);
      if (updateResponse.result === false) {
        await this.hideLoaderRequest();
        this.setCollapsedTableColumnsHeight();
        return AlertService.errorAlert(updateResponse.message, 'UPDATING PLAN FOR ' + this.selectedVesselForPlan.vessel_name.toUpperCase());
      }

      const vesselAuditDue = await this.getAuditDues({
        vessel_id : this.selectedVesselForPlan.vessel_id,
        for_audit_plan : true
      });

      let updatedVesselIndex = this.vessel_audit_dues.findIndex(vessel => vessel.id === this.selectedVesselForPlan.vessel_id);
      this.vessel_audit_dues[updatedVesselIndex] = vesselAuditDue[0];
      await this.hideLoaderRequest();
      this.resetSelectedVesselPlan();
      this.setCollapsedTableColumnsHeight();
      AlertService.successAlert('UPDATE SUCCESS', 'UPDATING PLAN FOR ' + this.selectedVesselForPlan.vessel_name.toUpperCase());
    },

    showUpdateVesselPlanModal(vessel) {
      if (this.isAllowed('vessel-ia.appointments.vessel-audit-due.edit-plan') === false) {
        return;
      }
      this.selectedVesselForPlan.vessel_id = vessel.id;
      this.selectedVesselForPlan.vessel_name = vessel.vessel_name.toUpperCase();
      this.selectedVesselForPlan.place = vessel.plan?.place ?? ''
      this.selectedVesselForPlan.auditors = vessel.plan?.auditors.map(auditor => auditor.id) ?? [];
      this.selectedVesselForPlan.date = vessel.plan?.date['date-picker'] ?? null;
      this.selectedVesselForPlan.remarks = vessel.plan?.remarks ?? '';

      const vesselCurrentData = this.vessel_audit_dues.filter(item => item.id === vessel.id);

      this.selectedVesselCurrentData.place = vesselCurrentData[0].plan?.place ?? ''
      this.selectedVesselCurrentData.auditors = vesselCurrentData[0].plan?.auditors.map(auditor => auditor.id) ?? [];
      this.selectedVesselCurrentData.date = vesselCurrentData[0].plan?.date['date-picker'] ?? null;
      this.selectedVesselCurrentData.remarks = vesselCurrentData[0].plan?.remarks ?? '';
      $('#updateVesselPlanModal').modal('show');
    },
    getClassByCount(vessel_id, type) {
      if (this.vessel_audit_dues_count[type].overdue.ids.includes(vessel_id) === true) {
        return 'overdue';
      }

      if (this.vessel_audit_dues_count[type]['30days'].ids.includes(vessel_id) === true) {
        return 'thirty_days';
      }

      if (this.vessel_audit_dues_count[type]['90days'].ids.includes(vessel_id) === true) {
        return 'ninety_days'
      }

      return '';

    },
    async setVesselsToTable(page=1) {
      this.loading = true;
      this.params.page=page
      this.params.perPage = 40;

      if (this.$route.params.vessel_id) {
        await this.getAuditDues({
          vessel_id : this.$route.params.vessel_id
        })
      } else {
        await this.getAuditDues(this.params);

      }
      this.loading = false;
      this.setColumnWidths();
      this.setCollapsedTableColumnsHeight()

    },
    runCallBack(param) {
      this.params.order_by = param.orderBy;
      this.params.direction = param.direction;
      if (param.direction === '') {
        this.currentOrderBy = '';
      }
      this.setVesselsToTable(1);
    },
    setColumnWidths() {
      setTimeout(() => {
        const pageHasScrollBar = document.documentElement.scrollHeight > document.documentElement.clientHeight;

        if (pageHasScrollBar === true) {
          this.adjustColumnPixel = '354px';
        } else {
          this.adjustColumnPixel = '344px';
        }
      }, 1)
    },
    setCollapsedTableColumnsHeight() {
      setTimeout(() => {
        const collapsibleTableRows = $('#collapsible_table').find('tbody tr');
        const dataTableRows = $('#data_table').find('tbody tr');

        for (let counter = 0; counter < collapsibleTableRows.length; counter++) {
          let rowHeight = $(collapsibleTableRows[counter]).height();
          $(dataTableRows[counter]).height(rowHeight);
        }
      }, 1)
    }
  },
  computed: {
    ...mapGetters([
      'pagination',
      'isLoading',
      'vessel_audit_dues',
      'vessel_audit_dues_count'
    ]),
    hasChanges() {
      const originalData = {
        auditors : this.selectedVesselCurrentData.auditors,
        date : this.selectedVesselCurrentData.date,
        place : this.selectedVesselCurrentData.place,
        remarks : this.selectedVesselCurrentData.remarks
      }

      const editedData = {
        auditors : this.selectedVesselForPlan.auditors,
        date : this.selectedVesselForPlan.date,
        place : this.selectedVesselForPlan.place,
        remarks : this.selectedVesselForPlan.remarks
      }
      return DataService.checkIfChanged(originalData, editedData);
    }
  },
  async created() {
    this.loading = true;
    this.setVessels = _.debounce(this.setVesselsToTable, 750)
    await this.getAuditDueCounts();
    await this.setVesselsToTable();
  },
  watch: {
    'params.active'() {
      this.setVessels()
    },
    'params.query'(){
      this.setVessels();
    }
  },

  destroyed() {
    this.params.active = 1;
  }

}
</script>

<style scoped>

.left-solid-border-black {
  border-left: 1px solid black !important;
}

.right-solid-border-black {
  border-right: 1px solid black !important;
}

.overdue {
  color: #fffeff;
  background-color: #ff0000;
}

.thirty_days {
  color: #fffeff;
  background-color: #ffa500;
}

.ninety_days {
  background-color: #f3f309;
}

td {
  word-wrap: break-word; /* Allows content to wrap within cell */
  white-space: normal; /* Allow text to wrap */
  background-color: white;
  border: 1px solid #e1e1e1 !important;
}

.plan-content {
  background-color: #ccffff;
}

.plan-data {
  border: 1px solid black !important;
}
</style>
